<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_orange_logo_horizontal.png"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen text-center" style="height:80vh;">

      <div class="m-auto pb-10">

        <div class="mb-12 relative">
          <router-link
            :to="{name: 'registration.v2.signup.phone'}"
            class="absolute left-0"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left.svg"
              class="inline-block"
            > Back
          </router-link>

          <span>Approximately 5 min. to complete</span>
        </div>

        <div class="mt-9">
          <h3 class="text text-center text-neutral mb-9">
            Thanks for registering at Relatus
          </h3>

          <h3 class="text text-center text-black mb-10 max-w-2xl">
            You officially have member-only access to our app. Snap a quick pic to download it from the app store
          </h3>

          <img
            src="@/assets/images/qr_code.png"
            style="display: inline-block;"
          >

          <div class="mt-10 pt-10 text-center">
            <router-link
              :to="{name: 'setup.profile.welcome'}"
              class="btn btn-primary inline-block w-3/5"
            >
              Take me to my profile setup
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OfficialMember'
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
